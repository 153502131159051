import React from "react";
import "./PrivacyPolicy.css";

function PrivacyPolicy() {
  return (
    <div className="privacy-policy-container">
      <h1>Privacy Policy</h1>
      <h2>DATA PROTECTION NOTICE FOR CUSTOMERS</h2>
      <p>
        This Data Protection Notice (“Notice”) sets out the basis which The Epic
        Mind Learning Loft Pte Ltd (“we”, “us”, or “our”) may collect, use,
        disclose or otherwise process personal data of our customers in
        accordance with the Personal Data Protection Act (“PDPA”). This Notice
        applies to personal data in our possession or under our control,
        including personal data in the possession of organisations which we have
        engaged to collect, use, disclose or process personal data for our
        purposes.
      </p>
      <h2>PERSONAL DATA</h2>
      <p>
        1. As used in this Notice: “customer” means an individual who (a) has
        contacted us through any means to find out more about any goods or
        services we provide, or (b) may, or has, entered into a contract with us
        for the supply of any goods or services by us; and “personal data” means
        data, whether true or not, about a customer who can be identified: (a)
        from that data; or (b) from that data and other information to which we
        have or are likely to have access.
      </p>
      <p>
        2. Depending on the nature of your interaction with us, some examples of
        personal data which we may collect from you include name, email address,
        telephone number and child's name, school, and level.
      </p>
      <p>
        3. Other terms used in this Notice shall have the meanings given to them
        in the PDPA (where the context so permits).
      </p>
      <h2>COLLECTION, USE AND DISCLOSURE OF PERSONAL DATA</h2>
      <p>
        4. We generally do not collect your personal data unless (a) it is
        provided to us voluntarily by you directly or via a third party who has
        been dpy authorised by you to disclose your personal data to us (your
        “authorised representative”) after (i) you (or your authorised
        representative) have been notified of the purposes for which the data is
        collected, and (ii) you (or your authorised representative) have
        provided written consent to the collection and usage of your personal
        data for those purposes, or (b) collection and use of personal data
        without consent is permitted or required by the PDPA or other laws. We
        shall seek your consent before collecting any additional personal data
        and before using your personal data for a purpose which has not been
        notified to you (except where permitted or authorised by law).
      </p>
      <p>
        5. We may collect and use your personal data for any or all of the
        following purposes:
      </p>
      <p>
        (a) performing obligations in the course of or in connection with our
        provision of the goods and/or services requested by you;
      </p>
      <p>
        (b) responding to, handling, and processing queries, requests,
        applications, complaints, and feedback from you; and
      </p>
      <p>(c) managing your relationship with us. </p>
      <p>
        6. We may disclose your personal data: (a) where such disclosure is
        required for performing obligations in the course of or in connection
        with our provision of the goods and services requested by you; or
      </p>
      <p>(b) We do not disclose our customers' data.. </p>
      <p>
        7. The purposes listed in the above clauses may continue to apply even
        in situations where your relationship with us (for example, pursuant to
        a contract) has been terminated or altered in any way, for a reasonable
        period thereafter (including, where applicable, a period to enable us to
        enforce our rights under a contract with you).
      </p>
      <h2>WITHDRAWING YOUR CONSENT </h2>
      <p>
        8. The consent that you provide for the collection, use and disclosure
        of your personal data will remain valid until such time it is being
        withdrawn by you in writing. You may withdraw consent and request us to
        stop collecting, using and/or disclosing your personal data for any or
        all of the purposes listed above by submitting your request in writing
        or via email to our Data Protection Officer at the contact details
        provided below.
      </p>
      <p>
        9. Upon receipt of your written request to withdraw your consent, we may
        require reasonable time (depending on the complexity of the request and
        its impact on our relationship with you) for your request to be
        processed and for us to notify you of the consequences of us acceding to
        the same, including any legal consequences which may affect your rights
        and liabilities to us. In general, we shall seek to process your request
        within seven (7) business days of receiving it.
      </p>
      <p>
        10. Whilst we respect your decision to withdraw your consent, please
        note that depending on the nature and scope of your request, we may not
        be in a position to continue providing our goods or services to you and
        we shall, in such circumstances, notify you before completing the
        processing of your request. Shopd you decide to cancel your withdrawal
        of consent, please inform us in writing in the manner described in
        clause 8 above.
      </p>
      <p>
        11. Please note that withdrawing consent does not affect our right to
        continue to collect, use and disclose personal data where such
        collection, use and disclose without consent is permitted or required
        under applicable laws.
      </p>
      <h2>ACCESS TO AND CORRECTION OF PERSONAL DATA</h2>
      <p>
        12. If you wish to make (a) an access request for access to a copy of
        the personal data which we hold about you or information about the ways
        in which we use or disclose your personal data, or (b) a correction
        request to correct or update any of your personal data which we hold
        about you, you may submit your request in writing or via email to our
        Data Protection Officer at the contact details provided below.
      </p>
      <p>
        13. Please note that a reasonable fee may be charged for an access
        request. If so, we will inform you of the fee before processing your
        request.
      </p>
      <p>
        14. We will respond to your request as soon as reasonably possible. In
        general, our response will be within seven (7) business days. Shopd we
        not be able to respond to your request within thirty (30) days after
        receiving your request, we will inform you in writing within thirty (30)
        days of the time by which we will be able to respond to your request. If
        we are unable to provide you with any personal data or to make a
        correction requested by you, we shall generally inform you of the
        reasons why we are unable to do so (except where we are not required to
        do so under the PDPA).
      </p>
      <h2>PROTECTION OF PERSONAL DATA </h2>
      <p>
        15. To safeguard your personal data from unauthorised access,
        collection, use, disclosure, copying, modification, disposal or similar
        risks, we have introduced appropriate administrative, physical and
        technical measures such as minimised collection of personal data,
        authentication and access controls (such as good password practices,
        need-to-basis for data disclosure, etc.), regpar patching of operating
        system and other software, securely erase storage media in devices
        before disposal, and usage of one time password(otp)/2 factor
        authentication (2fa)/mpti-factor authentication (mfa) to secure access.
      </p>
      <p>
        16. You shopd be aware, however, that no method of transmission over the
        Internet or method of electronic storage is completely secure. While
        security cannot be guaranteed, we strive to protect the security of your
        information and are constantly reviewing and enhancing our information
        security measures.
      </p>
      <h2>ACCURACY OF PERSONAL DATA </h2>
      <p>
        17. We generally rely on personal data provided by you (or your
        authorised representative). In order to ensure that your personal data
        is current, complete and accurate, please update us if there are changes
        to your personal data by informing our Data Protection Officer in
        writing or via email at the contact details provided below.
      </p>
      <h2>RETENTION OF PERSONAL DATA </h2>
      <p>
        18. We may retain your personal data for as long as it is necessary to
        fpfil the purpose for which it was collected, or as required or
        permitted by applicable laws.
      </p>
      <p>
        19. We will cease to retain your personal data, or remove the means by
        which the data can be associated with you, as soon as it is reasonable
        to assume that such retention no longer serves the purpose for which the
        personal data was collected, and is no longer necessary for legal or
        business purposes.
      </p>
      <h2>TRANSFERS OF PERSONAL DATA OUTSIDE OF SINGAPORE</h2>
      <p>
        20. We generally do not transfer your personal data to countries outside
        of Singapore. However, if we do so, we will obtain your consent for the
        transfer to be made and we will take steps to ensure that your personal
        data continues to receive a standard of protection that is at least
        comparable to that provided under the PDPA.
      </p>
      <h2>DATA PROTECTION OFFICER </h2>
      <p>
        21. You may contact our Data Protection Officer if you have any
        enquiries or feedback on our personal data protection policies and
        procedures, or if you wish to make any request, in the following manner:
      </p>
      <p>Name of DPO: Anne The Epic Mind Learning Loft</p>
      <p>Contact No.: 84444550</p>
      <p>Email Address: theepicmindlearningloft@gmail.com</p>
      <h2>EFFECT OF NOTICE AND CHANGES TO NOTICE </h2>
      <p>
        22. This Notice applies in conjunction with any other notices,
        contractual clauses and consent clauses that apply in relation to the
        collection, use and disclosure of your personal data by us.
      </p>
      <p>
        23. We may revise this Notice from time to time without any prior
        notice. You may determine if any such revision has taken place by
        referring to the date on which this Notice was last updated. Your
        continued use of our services constitutes your acknowledgement and
        acceptance of such changes.
      </p>
      Effective date : 28/03/2024 <br />
      Last updated : 28/03/2024
    </div>
  );
}

export default PrivacyPolicy;
